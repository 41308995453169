import { useEffect, useState } from "react";

import { getBlogsData, getUserLocation } from "@/client/OneistoxApiClient";

import headDetails from "@/content/head";

import Box from "@/design-system/Box";

import India from "@/components/Home/INDIA/India";
import Row from "@/components/Home/ROW/Row";
import Spinner from "@/components/LMSV3/common/Spinner";

export const getStaticProps = async () => {
  const content = headDetails;
  const blogs = await getBlogsData();
  return { props: { data: content, blogsList: blogs.data } };
};

const Index = ({ data, blogsList }: any) => {
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function handleLocation() {
      try {
        const userLocation = await getUserLocation();
        setLocation(userLocation?.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    handleLocation();
  }, []);

  if (loading)
    return (
      <Box display={"flex"} justifyContent={"center"} minHeight={"500px"} alignItems={"center"}>
        <Spinner />
      </Box>
    );
  if (!location) return <div>Error: {"Something went wrong"}</div>;

  return (
    <>
      {location === "IN" ? (
        <India data={data} blogsList={blogsList} />
      ) : (
        <Row data={data} blogsList={blogsList} />
      )}
    </>
  );
};

export default Index;
